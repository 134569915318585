export const getIsFullWidth = (dimensions: {
  width: number;
  height: number;
}) => {
  if (!dimensions) {
    return false;
  }

  // known behavior of platform https://github.com/wix-private/ecom/blob/master/client/wixstores-client/wixstores-client-core/src/media/mediaService.ts#L140
  return String(dimensions.width) === '100%' || String(dimensions.width) === '';
};
